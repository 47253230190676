import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserToGroupRequest,
  getGroupsForUserRequest,
  removeUserFromGroupRequest,
  updateUserRequest,
} from "../../../../actions";
import { UserGroups } from "../../../../enums/user-groups";
import { IRootReducer, UserModel } from "../../../../reducers";
import {
  CleanPhoneNumberUtil,
  ValidatePhoneNumberUtil,
} from "../../../../utils/string-utils";

interface EditUserProps {
  props: {
    rowCountSelected: number;
    userSelection: UserModel;
  };
}
const EditUser: FC<EditUserProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({} as UserModel);
  const [userRoles, setUserRoles] = useState([] as string[]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const initialGroups = useSelector(
    (state: IRootReducer) => state.adminReducer.groupsForUser
  );

  useEffect(() => {
    if (
      userDetails.phoneNumber !== "" &&
      userDetails.firstName !== "" &&
      userDetails.lastName !== "" &&
      userDetails.agency !== ""
    ) {
      if (ValidatePhoneNumberUtil(userDetails.phoneNumber)) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    } else if (
      userDetails.phoneNumber === "" &&
      userDetails.firstName !== "" &&
      userDetails.lastName !== "" &&
      userDetails.agency !== ""
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [
    userDetails.agency,
    userDetails.firstName,
    userDetails.lastName,
    userDetails.phoneNumber,
  ]);

  useEffect(() => {
    setUserRoles(initialGroups);
  }, [initialGroups]);

  const handleOpen = () => {
    setOpen(true);
    dispatch(getGroupsForUserRequest(props.userSelection?.cognitoUsername));
    setUserDetails(props.userSelection);
  };

  const handleInputChange = (e: any, field: string) => {
    let value: string = e as string;
    setUserDetails((current) => ({ ...current, [field]: value }));
  };

  const handleSelectAgency = (event: SelectChangeEvent<string>) => {
    const newAgency = event.target.value;
    setUserDetails((current) => ({ ...current, agency: newAgency }));
  };

  const handleCancel = () => {
    setOpen(false);
    setUserDetails({ phoneNumber: "", firstName: "", lastName: "", agency: ""} as UserModel);
  };

  const handleSave = () => {
    let cleanNumber = "";
    if (userDetails.phoneNumber) {
      cleanNumber = CleanPhoneNumberUtil(userDetails.phoneNumber);
    }

    let rolesToRemove = initialGroups.filter((r) => !userRoles.includes(r));
    let rolesToAdd = userRoles.filter((r) => !initialGroups.includes(r));

    dispatch(
      updateUserRequest(
        userDetails.cognitoUsername,
        userDetails.email,
        userDetails.firstName,
        userDetails.lastName,
        cleanNumber,
        userDetails.agency,
        userDetails.enabled,
        userRoles,
        rolesToAdd,
        rolesToRemove,
      )
    );
    rolesToAdd.forEach((r) =>
      dispatch(addUserToGroupRequest(userDetails.cognitoUsername, r))
    );
    rolesToRemove.forEach((r) =>
      dispatch(removeUserFromGroupRequest(userDetails.cognitoUsername, r))
    );
    setOpen(false);
  };

  const handleRoleSelect = (event: ChangeEvent<HTMLInputElement>) => {
    let newRoles = [...userRoles];
    if (
      event.target.checked === true &&
      newRoles.indexOf(event.target.id) === -1
    ) {
      newRoles.push(event.target.id);
    } else {
      newRoles = newRoles.filter((r) => r !== event.target.id);
    }

    setUserRoles(newRoles);
  };

  return (
    <>
      <Tooltip title="Edit a user in the VCC">
        <Box>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleOpen}
            style={{ minWidth: "150px", color: "black" }}
            disabled={props.rowCountSelected !== 1}
          >
            Edit User
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update user information and roles in the VCC
          </DialogContentText>
          <TextField
            autoFocus
            required
            variant="outlined"
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            disabled
            value={userDetails.email}
          />
          <TextField
            required
            variant="outlined"
            margin="dense"
            id="firstName"
            label="First Name"
            type="string"
            fullWidth
            value={userDetails.firstName}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
          />
          <TextField
            required
            variant="outlined"
            margin="dense"
            id="lastName"
            label="Last Name"
            type="string"
            fullWidth
            value={userDetails.lastName}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="phoneNumber"
            label="Phone Number"
            type="phoneNumber"
            fullWidth
            value={userDetails.phoneNumber}
            onChange={(e) => handleInputChange(e.target.value, e.target.id)}
          />
          <FormControl required fullWidth variant="outlined" margin="dense">
            <InputLabel>Agency</InputLabel>
            <Select
              fullWidth
              value={userDetails.agency}
              onChange={handleSelectAgency}
              defaultValue={userDetails.agency}
              label="Agency"
            >
              <MenuItem value={""}>(None)</MenuItem>
              <MenuItem value={"KCM"}>KCM</MenuItem>
              <MenuItem value={"NWSA"}>NWSA</MenuItem>
              <MenuItem value={"Port"}>Port</MenuItem>
              <MenuItem value={"SDOT"}>SDOT</MenuItem>
              <MenuItem value={"SFD"}>SFD</MenuItem>
              <MenuItem value={"SPD"}>SPD</MenuItem>
              <MenuItem value={"ST"}>ST</MenuItem>
              <MenuItem value={"WSDOT"}>WSDOT</MenuItem>
              <MenuItem value={"WSP"}>WSP</MenuItem>
            </Select>
          </FormControl>
          <Box m={1}>
            <Divider />
          </Box>
          <Box m={1}>
            <FormGroup>
              <InputLabel>User Roles</InputLabel>
              <Grid container>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={true} id={UserGroups.VCC_BASIC} />
                    }
                    label="Basic User"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows viewing dashboard, feed, collaboration spaces, and open incident models pages. Also allows viewing all map layers, details of other users, and all notifications. Allows updating the user's own profile and notification preferences.">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          userRoles.indexOf(UserGroups.INC_CONTRIBUTOR) > -1
                        }
                        id={UserGroups.INC_CONTRIBUTOR}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Contributor"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows user to add notes to Incident Models">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          userRoles.indexOf(UserGroups.INC_MANAGER) > -1
                        }
                        id={UserGroups.INC_MANAGER}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Manager"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to create, edit, close incidents, and create and edit mobility strategies. Allows for viewing and re-opening closed incidents, but not deleted incidents. If a user is set as an Incident Manager, they should also be set as an Incident Contributor as well">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          userRoles.indexOf(UserGroups.INC_RECORDS_MANAGER) >
                          -1
                        }
                        id={UserGroups.INC_RECORDS_MANAGER}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Incident Records Manager"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to view Incident Model Records Management page, to view and finalize reports">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          userRoles.indexOf(UserGroups.PIO) >
                          -1
                        }
                        id={UserGroups.PIO}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Public Information Officer"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to create, edit, close Public Information Officer actions Recommended to set PIO's as Incident Managers as well.">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={userRoles.indexOf(UserGroups.SITE_ADMIN) > -1}
                        id={UserGroups.SITE_ADMIN}
                        onChange={handleRoleSelect}
                      />
                    }
                    label="Site Administrator"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Box mt={1}>
                    <Tooltip title="Allows users to view Admin page, add and edit any user, and update user roles">
                      <InfoOutlinedIcon color="action" />
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </FormGroup>
          </Box>
          <DialogActions>
            <Button onClick={handleCancel} data-rum-id="user-cancel" color="inherit" variant="contained">
              Cancel
            </Button>
            <Button
              disabled={saveDisabled}
              onClick={handleSave}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditUser;
