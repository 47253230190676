import moment from "moment";
import { takeEvery, put, fork, select, call } from "redux-saga/effects";
import * as actions from "../../actions";
import { setDispatchAPIGeoFailure, setDispatchAPIGeoSuccess, setIncidentGeoFailure, setIncidentGeoPayload, setIncidentGeoSuccess, setSelectedEventIdsPayload, setSelectedEventIdsSuccess, types, setDispatchAPIGeoPayload, setCurrentIncidentGeoPayload, setCurrentIncidentGeoSuccess, setAssociatedDispatchGeoPayload, setAssociatedDispatchGeoSuccess, setAssociatedDispatchGeoFailure, createMapAnnotationPayload, updateMapAnnotationPayload, getMapAnnotationPayload, deleteMapAnnotationPayload, setDispatchDataGridGeoPayload } from "../../actions";
import * as api from "../../api";
import { DispatchGridRow } from "../../components/integrated-dispatch-feed/dispatch-event-feed-grid";
import { MapLayers, MarkerTypes } from "../../enums/map";
import { MapStoreError, MapStoreSuccess } from "../../enums/store-messages/map";
import { DispatchEventFeedModel, IRootReducer } from "../../reducers";
import { HighwayAlertData, LayerGeoData, MapAnnotationData, MarkerGeoData } from "../../reducers/states/map";
import { IncidentDispatch, VccIncidentModel } from "../../reducers/states/vcc-incident";
import { IsValidLatLongUtil } from "../../utils/number-utils";

export const selectedDispatchIdsStore = (state: IRootReducer) => state.mapReducer.selectedEventIds;
export const dispatchEventsStore = (state: IRootReducer) => state.dispatchEventFeedReducer.dispatchEvents;
export const allIncidentGeo = (state: IRootReducer) => state.mapReducer.incidentGeo;

export function* setDispatchGeoViaAPI(action: {
    type: typeof types.SET_DISPATCH_GEO;
    payload: setDispatchAPIGeoPayload;
  }): Generator<any, void, unknown> {
  try {
    var geoData: MarkerGeoData[] = [];
    var selectedEventIds: any = yield(select(selectedDispatchIdsStore));
    action.payload.dispatchEvents.forEach((de: DispatchEventFeedModel) => {
      if (IsValidLatLongUtil(de.latitude, de.longitude))
        geoData.push({
          mapId: MarkerTypes.DISPATCH + de.eventNumber,
          entityId: de.eventNumber,
          latitude: de.latitude!,
          longitude: de.longitude!,
          description: moment(de.eventDate).format("LL, HH:mm:ss") + " | " + de.eventType,
          markerType: MarkerTypes.DISPATCH,
          isSelected: de.eventNumber in selectedEventIds,
          isClosed: de.status === "CLOSED",
        });
    });
    yield(put(setDispatchAPIGeoSuccess(geoData)));
  } catch (e) {
    yield(put(setDispatchAPIGeoFailure(MapStoreError.SET_DISPATCH_GEO)));
  }
}
function* watchSetDispatchAPIGeo() {
  yield takeEvery(actions.types.SET_DISPATCH_GEO, setDispatchGeoViaAPI);
}

export function* setDispatchGeoViaDataGrid(action: {
  type: typeof types.SET_DISPATCH_GEO_VIA_GRID;
  payload: setDispatchDataGridGeoPayload;
}): Generator<any, void, unknown> {
try {
  var geoData: MarkerGeoData[] = [];
  var selectedEventIds: any = yield(select(selectedDispatchIdsStore));
  action.payload.dispatchEvents.forEach((de: DispatchGridRow) => {
    if (IsValidLatLongUtil(de.latitude, de.longitude))
      geoData.push({
        mapId: MarkerTypes.DISPATCH + de.eventNumber,
        entityId: de.eventNumber,
        latitude: de.latitude!,
        longitude: de.longitude!,
        description: moment(de.eventStartDate).format("LL, HH:mm:ss") + " | " + de.eventType,
        markerType: MarkerTypes.DISPATCH,
        isSelected: de.eventNumber in selectedEventIds,
        isClosed: de.status === "CLOSED",
      });
  });
  yield(put(setDispatchAPIGeoSuccess(geoData)));
} catch (e) {
  yield(put(setDispatchAPIGeoFailure(MapStoreError.SET_DISPATCH_GEO)));
}
}
function* watchSetDispatchDataGridGeo() {
yield takeEvery(actions.types.SET_DISPATCH_GEO_VIA_GRID, setDispatchGeoViaDataGrid);
}

export function* setAssociatedDispatchEventsGeo(action: {
  type: typeof types.SET_ASSOCIATED_DISPATCH_GEO;
  payload: setAssociatedDispatchGeoPayload;
}): Generator<any, void, unknown> {
try {
  var geoData: MarkerGeoData[] = [];
  action.payload.dispatchEvents.forEach((de: IncidentDispatch) => {
    if (IsValidLatLongUtil(de.latitude, de.longitude))
      geoData.push({
        mapId: MarkerTypes.ASSOC_DISPATCH + de.id,
        entityId: de.id,
        latitude: de.latitude!,
        longitude: de.longitude!,
        description: moment(de.startTime).format("LL, HH:mm:ss") + " | " + de.location,
        markerType: MarkerTypes.DISPATCH,
        isSelected: false,
        isClosed: false, // Review: Could update this to show closed dispatches on Incident Detail Map
      });
  });
  
  yield(put(setAssociatedDispatchGeoSuccess(geoData)));
} catch (e) {
  yield(put(setAssociatedDispatchGeoFailure(MapStoreError.SET_ASSOCIATED_DISPATCH_GEO)));
}
}
function* watchSetAssociatedDispatchEventsGeo() {
  yield takeEvery(actions.types.SET_ASSOCIATED_DISPATCH_GEO, setAssociatedDispatchEventsGeo);
}

export function* setSelectedDispatchIds(action: {
  type: typeof types.SET_SELECTED_DISPATCH_IDS;
  payload: setSelectedEventIdsPayload;
}): Generator<any, void, unknown> {
  try {
    var geoData: MarkerGeoData[] = [];
    var dispatchEvents: any = yield(select(dispatchEventsStore));
    dispatchEvents.data.forEach((de: DispatchEventFeedModel) => {
      if (IsValidLatLongUtil(de.latitude, de.longitude))
        geoData.push({
          mapId: MarkerTypes.DISPATCH + de.eventNumber,
          entityId: de.eventNumber,
          latitude: de.latitude!,
          longitude: de.longitude!,
          description: moment(de.eventDate).format("LL, HH:mm:ss") + " | " + de.eventType,
          markerType: MarkerTypes.DISPATCH,
          isSelected: de.eventNumber in action.payload.selectedEventIds,
          isClosed: de.status === "CLOSED",
        });
    });
    yield(put(setSelectedEventIdsSuccess(action.payload.selectedEventIds)))
    yield(put(setDispatchAPIGeoSuccess(geoData)));
    
  } catch (e) {
    yield(put(setDispatchAPIGeoFailure(MapStoreError.SET_SELECTED_IDS)));
  }
}
function* watchSetSelectedDispatchIds() {
  yield takeEvery(actions.types.SET_SELECTED_DISPATCH_IDS, setSelectedDispatchIds);
}

export function* setIncidentGeo(action: {
    type: typeof types.SET_INCIDENT_GEO;
    payload: setIncidentGeoPayload;
  }): Generator<any, void, unknown> {
  try {
    var geoData: MarkerGeoData[] = [];
    action.payload.incidents.forEach((inc: VccIncidentModel) => {
        if (IsValidLatLongUtil(inc.latitude, inc.longitude))
          geoData.push({
            mapId: MarkerTypes.INCIDENT + inc.id,
            entityId: inc.id,
            latitude: inc.latitude!,
            longitude: inc.longitude!,
            description: moment(inc.startTime).format("LL, HH:mm:ss") + " | " + inc.type,
            markerType: MarkerTypes.INCIDENT,
            isSelected: false,
            isClosed: inc.status === "CLOSED",
          });
      });
    yield(put(setIncidentGeoSuccess(geoData)));
  } catch (e) {
    yield(put(setIncidentGeoFailure(MapStoreError.SET_VCC_INCIDENT_GEO)));
  }
}
function* watchSetIncidentGeo() {
  yield takeEvery(actions.types.SET_INCIDENT_GEO, setIncidentGeo);
}

export function* setCurrentIncidentGeo(action: {
  type: typeof types.SET_CURRENT_INCIDENT_GEO;
  payload: setCurrentIncidentGeoPayload;
}): Generator<any, void, unknown> {
try {
  if(IsValidLatLongUtil(action.payload.incident.latitude, action.payload.incident.longitude)){
    var geoData: MarkerGeoData = {
      mapId: MarkerTypes.CURRENT_INC + action.payload.incident.id,
      entityId: action.payload.incident.id,
      latitude: action.payload.incident.latitude!,
      longitude: action.payload.incident.longitude!,
      description: moment(action.payload.incident.startTime).format("LL, HH:mm:ss") + " | " + action.payload.incident.type,
      markerType: MarkerTypes.INCIDENT,
      isSelected: false,
      isClosed: action.payload.incident.status === "CLOSED",
    };
    yield(put(setCurrentIncidentGeoSuccess([geoData])));
  }
  else{
    yield(put(setCurrentIncidentGeoSuccess([])));
  }
} catch (e) {
  yield(put(setIncidentGeoFailure(MapStoreError.SET_CURRENT_VCC_INCIDENT_GEO)));
}
}
function* watchSetCurrentIncidentGeo() {
  yield takeEvery(actions.types.SET_CURRENT_INCIDENT_GEO, setCurrentIncidentGeo);
}

export function* createMapAnnotation(action: {
  type: typeof types.CREATE_MAP_ANNOTATION;
  payload: createMapAnnotationPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.createMapAnnotation, action.payload.incId, action.payload.geoId, action.payload.geojsonString, action.payload.note, action.payload.primaryLocation, action.payload.perimeterRadius);
    if (result && result.status === 200) {
        yield put(actions.createMapAnnotationSuccess(MapStoreSuccess.CREATE_MAP_ANNOTATION));
    } else {
      yield put( actions.createMapAnnotationFailure(MapStoreError.CREATE_MAP_ANNOTATION));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
    yield put( actions.createMapAnnotationFailure(MapStoreError.CREATE_MAP_ANNOTATION));
  }
}
function* watchCreateMapAnnotation() {
  yield takeEvery(actions.types.CREATE_MAP_ANNOTATION, createMapAnnotation);
}

export function* updateMapAnnotation(action: {
  type: typeof types.UPDATE_MAP_ANNOTATION;
  payload: updateMapAnnotationPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.updateMapAnnotation, action.payload.incId, action.payload.geoId, action.payload.geojsonString, action.payload.primaryLocation, action.payload.perimeterRadius);
    if (result && result.status === 200) {
        yield put(actions.updateMapAnnotationSuccess(MapStoreSuccess.UPDATE_MAP_ANNOTATION));
    } else {
      yield put( actions.updateMapAnnotationFailure(MapStoreError.UPDATE_MAP_ANNOTATION));
    }
  } catch (e) {
    // Future: Implement proper logging and error handling
    console.log(e);
    yield put( actions.updateMapAnnotationFailure(MapStoreError.UPDATE_MAP_ANNOTATION));
  }
}
function* watchUpdateMapAnnotation() {
  yield takeEvery(actions.types.UPDATE_MAP_ANNOTATION, updateMapAnnotation);
}

export function* deleteMapAnnotation(action: {
  type: typeof types.DELETE_MAP_ANNOTATION;
  payload: deleteMapAnnotationPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.deleteMapAnnotation, action.payload.incId, action.payload.geoId);
    if (result && result.status === 200) {
        yield put(actions.deleteMapAnnotationSuccess(MapStoreSuccess.DELETE_MAP_ANNOTATION));
    } else {
      yield put( actions.deleteMapAnnotationFailure(MapStoreError.DELETE_MAP_ANNOTATION));
    }
  } catch (e) {
    yield put( actions.deleteMapAnnotationFailure(MapStoreError.DELETE_MAP_ANNOTATION));
  }
}
function* watchDeleteMapAnnotation() {
  yield takeEvery(actions.types.DELETE_MAP_ANNOTATION, deleteMapAnnotation);
}

export function* getMapAnnotations(action: {
  type: typeof types.GET_MAP_ANNOTATIONS;
  payload: getMapAnnotationPayload;
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getMapAnnotations, action.payload.incId);
    if (result && result.status === 200) {
        let apiResult: MapAnnotationData[] = result.data;
        yield put(actions.getMapAnnotationSuccess(apiResult));
    } else {
      yield put( actions.getMapAnnotationsFailure(MapStoreError.GET_MAP_ANNOTATIONS));
    }
  } catch (e) {
    yield put( actions.getMapAnnotationsFailure(MapStoreError.GET_MAP_ANNOTATIONS));
  }
}
function* watchGetMapAnnotation() {
  yield takeEvery(actions.types.GET_MAP_ANNOTATIONS, getMapAnnotations);
}


export function* getGetHighwayAlertsData(action: {
  type: typeof types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER;
  payload: {};
}): Generator<any, void, unknown> {
  try {
    const result: any = yield call(api.getHighwayAlerts);
    if (result && result.status === 200) {
        let apiResult: HighwayAlertData[] = result.data;
        let geoFeatures: GeoJSON.Feature[] = [] as GeoJSON.Feature[];
        apiResult.forEach(i => {
          geoFeatures.push({
            type: "Feature",
            properties: i.Properties,
            geometry: i.Geometry
          } as GeoJSON.Feature);
        });

        let geoCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
          type: "FeatureCollection",
          features: geoFeatures
        } as GeoJSON.FeatureCollection<GeoJSON.Geometry>;

        
        let apiGeoLayer: LayerGeoData = {
          id: MapLayers.HIGHWAY_ALERTS_DATA,
          geoData: geoCollection,
        } as LayerGeoData;

        console.log(apiGeoLayer);
        //make sure layergeodata is full
        yield put(actions.getTravelerInformationSuccess(apiGeoLayer));
    } else {
      yield put( actions.getTravelerInformationFailure(MapStoreError.GET_TRAVELER_INFORMATION_ALERT_DATA));
    }
  } catch (e) {
    yield put( actions.getTravelerInformationFailure(MapStoreError.GET_TRAVELER_INFORMATION_ALERT_DATA));
  }
}


function* watchGetHighwayAlerts() {
  yield takeEvery(actions.types.GET_TRAVELER_INFORMATION_INCIDENT_LAYER, getGetHighwayAlertsData);
}


const mapSagas = [
    fork(watchSetDispatchAPIGeo),
    fork(watchSetDispatchDataGridGeo),
    fork(watchSetSelectedDispatchIds),
    fork(watchSetIncidentGeo),
    fork(watchSetCurrentIncidentGeo),
    fork(watchSetAssociatedDispatchEventsGeo),
    fork(watchCreateMapAnnotation),
    fork(watchUpdateMapAnnotation),
    fork(watchDeleteMapAnnotation),
    fork(watchGetMapAnnotation),
    fork(watchGetHighwayAlerts),
    
  ];

export default mapSagas;
