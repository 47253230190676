import { Alert, Box, Button, Grid, Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModeIcon from '@mui/icons-material/Mode';
import mapboxgl from "mapbox-gl";
import { IRootReducer } from "../../../reducers";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import "mapbox-gl/dist/mapbox-gl.css";
import { MarkerData, MarkerGeoData } from "../../../reducers/states/map";
import { defaultView } from "../../../reducers/map";
import VccControlPanel from "../vcc-control-panel";
import { DrawTypes, MapLayers } from "../../../enums/map";
import { addCameraImageUtil, addCameraLayerListenersUtil, addCameraSourceUtil, addTrafficSourceUtil, createMarkerUtil, drawCameraLayerUtil, drawTrafficLayerUtil, updateAnnotationSourceUtil, addEmptySourceUtil, drawAnnotationLayerUtil, createCircleFromPtUtil, addTravelerInfoDataImageUtil, drawTravelerInfoDataUtil, addTravelerInfoDataListenersUtil } from "../../../utils/map-utils";
import CameraModal, { CameraData } from "../camera-modal";
import TrafficPanel from "../traffic-panel";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { createMapAnnotationRequest, deleteMapAnnotationRequest, getTravelerInformationRequest, getMapAnnotationsRequest, setIncAddressFromMapRequest, setIncLatLongFromMapRequest, setMapEditIncLocationRequest, updateMapAnnotationRequest } from "../../../actions";
import AnnotationControlPanel from "../annotation-control-panel";
import { MapStoreError, MapStoreSuccess } from "../../../enums/store-messages/map";
import useUserPermissions from "../../../hooks/user-permissions";
import { RoleAction } from "../../../enums/permission-actions";
import PerimeterDetailsModal from "../annotate-modal";
import ConfirmationModal from "../../common/confirmation-modal";
import IncLocationControlPanel from "../incident-location-control-panal";
import _ from "lodash";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
const useStyles = makeStyles(() => ({
  layerPanel: {
    position: "absolute",
    float: "left",
    zIndex: 1,
  },
  annotateButton: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    width: "100%",
    zIndex: 1,
  },
}));

interface MapProps {
  props: {
    markerData: MarkerData[];
    initLayers: string[];
    initMarkerSets: string[];
    allAvailableLayers: string[];
    allAvailableMarkerSets: string[]
    incId?: string;
    showAnnotateTools?: boolean;
    isAddMarker?: boolean;
    markerOnClick: (markerType: string, markerId: string) => void;
  };
}

const VccMap: FC<MapProps> = ({props}) => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const classes = useStyles();
  const [annotationsHidden] = useUserPermissions(RoleAction.EDIT_INCIDENT);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [vccMap, setVccMap] = useState(null as unknown as mapboxgl.Map);
  const [vccDraw, setVccDraw] = useState(null as unknown as MapboxDraw);
  const [drawType, setDrawType] = useState("");
  const [newFeatures, setNewFeatures] = useState([] as GeoJSON.Feature[]);
  const [updatedFeatures, setUpdatedFeatures] = useState([] as GeoJSON.Feature[]);
  const [deletedFeatures, setDeletedFeatures] = useState([] as GeoJSON.Feature[]);
  const [isDoneSaving, setIsDoneSaving] = useState(false);
  const [viewport, setViewport] = useState(defaultView);
  const [mapboxglMarkerMap, setMapboxglMarkerMap] = useState(new Map<string, mapboxgl.Marker>() as Map<string, mapboxgl.Marker>); // Mapbox object used to draw on the actual map
  const [drawnMarkersMap, setDrawnMarkersMap] = useState(new Map<string, MarkerGeoData[]>() as Map<string, MarkerGeoData[]>); // VCC Object to track when to update mapbox markers
  const [visibleMarkerSets, setVisibleMarkerSets] = useState(props.initMarkerSets);
  const [showAnnotationControl, setShowAnnotationControl] = useState(false);
  const [confirmDiscardEdit, setConfirmDiscardEdit] = useState(false);
  const [isPerimeterModalOpen, setIsPerimeterModalOpen] = useState(false);
  const [perimeterRadius, setPerimeterRadius] = useState(0.5) // .5 mi default
  const [perimeterNote, setPerimeterNote] = useState("")
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [cameraData, setCameraData] = useState({} as CameraData)
  const [errorMessage, setErrorMessage] = useState("");
  
  const geoStore = useSelector((state: IRootReducer) => state.geoJsonReducer);
  const mapStore = useSelector((state: IRootReducer) => state.mapReducer);

  // Load any Geometries
  useEffect(() => {
    if (props.incId){
      dispatch(getMapAnnotationsRequest(props.incId!));
    }
  }, [dispatch, props.incId, vccMap]);

  // Load default layers and clear any controls on initial load
  useEffect(() => {
    dispatch(getTravelerInformationRequest());
    dispatch(setMapEditIncLocationRequest(false));
  }, [dispatch]);

  useEffect(() => {
    if(mapStore.viewport.latitude && mapStore.viewport.longitude){
      setViewport({
        latitude: mapStore.viewport.latitude,
        longitude: mapStore.viewport.longitude,
        zoom: mapStore.viewport.zoom,
      });
    }
  },[mapStore.viewport] );

  useEffect(() => {
    if(vccMap){
      vccMap.flyTo({
        center: [viewport.longitude, viewport.latitude],
        zoom: viewport.zoom,
        essential: true,
      });
    }
  }, [vccMap, viewport.latitude, viewport.longitude, viewport.zoom])

  useEffect(() => {
    if(mapStore.successMessage){
      switch(mapStore.successMessage){
        case MapStoreSuccess.CREATE_MAP_ANNOTATION:
        case MapStoreSuccess.UPDATE_MAP_ANNOTATION:
        case MapStoreSuccess.DELETE_MAP_ANNOTATION:
          if(props.incId){
            dispatch(getMapAnnotationsRequest(props.incId));
          }
          break;
      }
    }
  }, [dispatch, newFeatures, props.incId, mapStore.currentIncidentGeo, mapStore.successMessage, vccDraw])

  useEffect(() => {
    if(mapStore.failureMessage){
      switch(mapStore.failureMessage){
        case MapStoreError.CREATE_MAP_ANNOTATION:
        case MapStoreError.UPDATE_MAP_ANNOTATION:
        case MapStoreError.DELETE_MAP_ANNOTATION:
          setErrorMessage(mapStore.failureMessage);
          setIsDoneSaving(prev => !prev);
          break;
      }
    }
  }, [mapStore.failureMessage])

  const handleCameraLayerClick = (location: string, imgUrl: string) => {
    setCameraData({ location, imgUrl } as CameraData)
    setIsCameraModalOpen(true);
  }

  // Initialize the map
  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_API_KEY || "";
    let map: mapboxgl.Map;

    async function initializeMap() {
      // We only want to initialize the underlying mapbox map after the div has been rendered
      if (mapRef.current != null) {
        map = new mapboxgl.Map({
          container: mapRef.current,
          style: "mapbox://styles/mapbox/light-v10?optimize=true",
          center: [viewport.longitude, viewport.latitude],
          zoom: viewport.zoom,
        });

        // zoom in/out control; disable compass tool here (this does pitch/bearing reset on the map)
        map.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false
          }),
          "top-right"
        );

        // fullscreen control
        map.addControl(new mapboxgl.FullscreenControl(), "top-right");

        // Initialize Draw Controls
        const draw = new MapboxDraw({
          displayControlsDefault: false,
          controls: {
            polygon: false,
            line_string: false,
            point: false,
            trash: true
          },
        });
        setVccDraw(draw);

        // Init map with unchanging layers
        map.once("load", function () {
          // Camera Layer
          addCameraSourceUtil(map, geoStore.geoJson); // TODO: Move the camera init into the map - leverage the addEmptySourceUtil
          addCameraImageUtil(map);
          addCameraLayerListenersUtil(map, handleCameraLayerClick);
          // Traveler Info Highway ALert Layer
          addEmptySourceUtil(map, MapLayers.HIGHWAY_ALERTS_DATA);
          addTravelerInfoDataImageUtil(map);
          addTravelerInfoDataListenersUtil(map);
          
          // Mapbox traffic layer
          addTrafficSourceUtil(map);

          // Map Annotation layers
          addEmptySourceUtil(map, MapLayers.ANNOTATIONS_PERIMETER);
          addEmptySourceUtil(map, MapLayers.ANNOTATIONS_DETOUR);
          addEmptySourceUtil(map, MapLayers.ANNOTATIONS_BLOCKAGE);
          //console.log(props.initLayers);
          if(props.initLayers.includes(MapLayers.TRAFFIC_LAYER)){
            drawTrafficLayerUtil(map);
          }
          if(props.initLayers.includes(MapLayers.CAMERA_LAYER)){
            drawCameraLayerUtil(map);
          }
          if(props.initLayers.includes(MapLayers.CONSTRUCTION_TRAFFIC_DATA)){
          }
          if(props.initLayers.includes(MapLayers.HIGHWAY_ALERTS_DATA)){
            drawTravelerInfoDataUtil(map);
          }
          if(props.initLayers.includes(MapLayers.ALL_ANNOTATIONS)){
            drawAnnotationLayerUtil(map, MapLayers.ANNOTATIONS_PERIMETER);
            drawAnnotationLayerUtil(map, MapLayers.ANNOTATIONS_DETOUR);
            drawAnnotationLayerUtil(map, MapLayers.ANNOTATIONS_BLOCKAGE);
          }

          // Mark map initialized once the load listener finishes
          setMapInitialized(true);
        });
        
        // Save the map in a react state to update after initting
        setVccMap(map);
      }
    }
    initializeMap();

    // Cleans up and mapbox DOM elements and other resources - https://maplibre.org/maplibre-gl-js-docs/api/map/#map#remove
    return function cleanup() {
      if (map != null) map.remove();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoStore.geoJson]);

  // Incident Marker Update Logic
  const handleUpdateMarker = useCallback( (event: any) => {
    var coordinates = event.lngLat;
    dispatch(setIncLatLongFromMapRequest(coordinates.lat, coordinates.lng))
  }, [dispatch]); 

  // Incident Marker Update Toggle Logic
  useEffect(() => {
    if(mapInitialized){
      if(mapStore.isEditIncLocation){
        if(showAnnotationControl){
          setShowAnnotationControl(false)
        }
        vccMap.getCanvas().style.cursor = "pointer";
        vccMap.on('click', handleUpdateMarker);
      }
      else {
        vccMap.getCanvas().style.cursor = "";
        vccMap.off('click', handleUpdateMarker);
      }
    }
  }, [mapInitialized, props.isAddMarker, mapStore.isEditIncLocation, vccMap, dispatch, handleUpdateMarker, showAnnotationControl])

  // Hide or show Annotation Tools
  useEffect(() => {
    if(vccMap && vccDraw){
      // Annotation Controls
      if(showAnnotationControl){
        vccMap.addControl(vccDraw);
        setVccDraw(vccDraw);
      }
      else{
        if(vccMap.hasControl(vccDraw)){
          vccMap.removeControl(vccDraw);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAnnotationControl])

  // Loading Annotations
  useEffect(() => {
    if(mapInitialized && props.showAnnotateTools){
      if(mapStore.mapAnnotations){
        // Collect features in db and add to the respective sources
        let newPerimeters: GeoJSON.Feature[] = [];
        let newDetours: GeoJSON.Feature[] = [];
        let newBlockage: GeoJSON.Feature[] = [];
        mapStore.mapAnnotations.forEach(geo => {
          let parsedGeo: GeoJSON.Feature = JSON.parse(geo.geoJsonString);
          switch(parsedGeo.properties?.vccType){
            case DrawTypes.PERIMETER:
              newPerimeters.push(parsedGeo);
              break;
            case DrawTypes.DETOUR:
              newDetours.push(parsedGeo);
              break;
            case DrawTypes.BLOCKAGE:
              newBlockage.push(parsedGeo);
              break;
          }
        })

        if(vccMap.getSource(MapLayers.ANNOTATIONS_PERIMETER)){
          updateAnnotationSourceUtil(vccMap, MapLayers.ANNOTATIONS_PERIMETER, newPerimeters);
        }
        if(vccMap.getSource(MapLayers.ANNOTATIONS_DETOUR)){
          updateAnnotationSourceUtil(vccMap, MapLayers.ANNOTATIONS_DETOUR, newDetours);
        }
        if(vccMap.getSource(MapLayers.ANNOTATIONS_BLOCKAGE)){
          updateAnnotationSourceUtil(vccMap, MapLayers.ANNOTATIONS_BLOCKAGE, newBlockage);
        }

        // Remove the temp draw features once annotation source layers have been updated
        newFeatures.forEach( f => vccDraw.delete(f.id as string))
          setNewFeatures([]);
          setUpdatedFeatures([]);
          setDeletedFeatures([]);
          setIsDoneSaving(prev => !prev);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showAnnotateTools, mapStore.mapAnnotations, vccMap, mapInitialized])

  // Traveler Info Layer
  useEffect(() => {
    if(mapInitialized){
      console.log("Here now?");
      if(mapStore.TravelerInfoLayer && mapStore.TravelerInfoLayer.geoData !== undefined && mapStore.TravelerInfoLayer.geoData.features !== undefined){
        if(vccMap.getSource(MapLayers.HIGHWAY_ALERTS_DATA)){
          updateAnnotationSourceUtil(vccMap, MapLayers.HIGHWAY_ALERTS_DATA, mapStore.TravelerInfoLayer.geoData.features);
        }
      }
    }
  }, [mapInitialized, mapStore.TravelerInfoLayer, vccMap])

  // New Annotations - Save Annotations
  useEffect(() => {
    function drawAnnotation(event: any) {
      let newFeature: GeoJSON.Feature;
      switch(drawType){
        case DrawTypes.PERIMETER:
          newFeature = createCircleFromPtUtil({...event.features[0]}, perimeterRadius);
          newFeature = {...newFeature, properties: {description: "Perimeter Set - " + perimeterRadius + " mi radius", note: perimeterNote, vccType: DrawTypes.PERIMETER, primaryLocation: event.features[0].geometry.coordinates, perimeterRadius: perimeterRadius}}
          vccDraw.add(newFeature);
          vccDraw.delete(event.features[0].id);
          setNewFeatures([...newFeatures, newFeature])
          break;
        case DrawTypes.DETOUR:
          newFeature = {...event.features[0], properties: {description: "Detour Set", vccType: DrawTypes.DETOUR, primaryLocation: event.features[0].geometry.coordinates, perimeterRadius: ""}}
          setNewFeatures([...newFeatures, newFeature])
          break;
        case DrawTypes.BLOCKAGE:
          newFeature = {...event.features[0], properties: {description: "Blockage Set", vccType: DrawTypes.BLOCKAGE, primaryLocation: event.features[0].geometry.coordinates, perimeterRadius: ""}}
          setNewFeatures([...newFeatures, newFeature])
          break;
      }
    }

    if(vccMap){
      vccMap.once('draw.create', drawAnnotation);
    }

    return () => {
      if(vccMap){
        vccMap.off('draw.create', drawAnnotation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerData, dispatch, mapStore.currentIncidentGeo, drawType])

  // Updated Annotations - Save Annotations
  useEffect(() => {
    function updateAnnotation(event: any) {
      let updatedFeature: GeoJSON.Feature;
      switch(event.features[0].properties.vccType){
        case DrawTypes.PERIMETER:
          updatedFeature = {...event.features[0], properties: {description: event.features[0].properties.description, note: event.features[0].properties.note, vccType: DrawTypes.PERIMETER, primaryLocation: event.features[0].properties.primaryLocation, perimeterRadius: event.features[0].properties.perimeterRadius}}
          setNewFeatures(newFeatures.filter(n => n.id !== updatedFeature.id));
          setUpdatedFeatures([...updatedFeatures, updatedFeature])
          break;
        case DrawTypes.DETOUR:
          updatedFeature = {...event.features[0], properties: {description: event.features[0].properties.description, vccType: DrawTypes.DETOUR, primaryLocation: event.features[0].properties.primaryLocation, perimeterRadius: ""}}
          setNewFeatures(newFeatures.filter(n => n.id !== updatedFeature.id));
          setUpdatedFeatures([...updatedFeatures, updatedFeature])
          break;
        case DrawTypes.BLOCKAGE:
          updatedFeature = {...event.features[0], properties: {description: event.features[0].properties.description, vccType: DrawTypes.BLOCKAGE, primaryLocation: event.features[0].properties.primaryLocation, perimeterRadius: ""}}
          setNewFeatures(newFeatures.filter(n => n.id !== updatedFeature.id));
          setUpdatedFeatures([...updatedFeatures, updatedFeature])
          break;
      }
    }

    if(vccMap){
      vccMap.on('draw.update', updateAnnotation);
    }

    return () => {
      if(vccMap){
        vccMap.off('draw.update', updateAnnotation);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerData, dispatch, mapStore.currentIncidentGeo, drawType])

  // Delete Annotations - Save Annotations
  useEffect(() => {
    function deleteAnnotation(event: any) {
      let deletedFeature: GeoJSON.Feature;
      switch(event.features[0].properties.vccType){
        case DrawTypes.PERIMETER:
          deletedFeature = {...event.features[0], properties: {description: "Perimeter Set", vccType: DrawTypes.PERIMETER}}
          setNewFeatures(newFeatures.filter(d => d.id !== deletedFeature.id));
          setDeletedFeatures([...deletedFeatures, deletedFeature])
          break;
        case DrawTypes.DETOUR:
          deletedFeature = {...event.features[0], properties: {description: "Detour Set", vccType: DrawTypes.DETOUR}}
          setNewFeatures(newFeatures.filter(d => d.id !== deletedFeature.id));
          setDeletedFeatures([...deletedFeatures, deletedFeature])
          break;
        case DrawTypes.BLOCKAGE:
          deletedFeature = {...event.features[0], properties: {description: "Blockage Set", vccType: DrawTypes.BLOCKAGE}}
          setNewFeatures(newFeatures.filter(d => d.id !== deletedFeature.id));
          setDeletedFeatures([...deletedFeatures, deletedFeature])
          break;
      }
    }

    if(vccMap){
      vccMap.once('draw.delete', deleteAnnotation);
    }

    return () => {
      if(vccMap){
        vccMap.off('draw.delete', deleteAnnotation);
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerData, dispatch, mapStore.currentIncidentGeo, drawType])

  // Marker Updates - display events and reload when data changes
  useEffect(() => {
    if(vccMap){
      // Remove any mapboxgl markers from the map that are no longer in the geo data or toggled off via the legend
      let updatedMapboxglMarkers: Map<string, mapboxgl.Marker> = mapboxglMarkerMap;
      let newPointIds = props.markerData.flatMap( markerData => markerData.geoData.map(point => point.mapId));
      let legendFilteredIds = props.markerData.filter( md => !visibleMarkerSets.includes(md.category)).flatMap(md => md.geoData).map(point => point.mapId);
      mapboxglMarkerMap.forEach((marker, id) => {
        if(!newPointIds.includes(id) || legendFilteredIds.includes(id)){
          marker.remove();
          updatedMapboxglMarkers.delete(id);
        }
      })

      // Gather geo data to determine if they need to be updated on the map
      let updatedGeoMap: Map<string, MarkerGeoData[]> = new Map<string, MarkerGeoData[]>();
      props.markerData.forEach(m => {
        if(visibleMarkerSets.includes(m.category)){
          updatedGeoMap.set(m.category, m.geoData)
        }
      });
      
      // Loop through the categories and update the map selectively
      props.markerData.forEach( markerData => {
        updatedGeoMap.get(markerData.category)?.forEach( point => {
          // Check if the marker exists, and if any details have been updated and it needs to be redrawn
          let existingPoint = drawnMarkersMap.get(markerData.category)?.find(p => p.mapId === point.mapId);
          if(existingPoint){
            if(!_.isEqual(existingPoint, point)){
              // Update the existing marker with the new information
              mapboxglMarkerMap.get(point.mapId)?.remove();
              let updatedMarker: mapboxgl.Marker = createMarkerUtil(vccMap, point, markerData.color, props.markerOnClick);
              updatedMarker.addTo(vccMap);
              updatedMapboxglMarkers.set(point.mapId, updatedMarker);
            }
          }
          else{
            // New marker to draw - add it to the map
            let marker: mapboxgl.Marker = createMarkerUtil(vccMap, point, markerData.color, props.markerOnClick);
            marker.addTo(vccMap);
            updatedMapboxglMarkers.set(point.mapId, marker);
          }
        });
      })
      setMapboxglMarkerMap(updatedMapboxglMarkers);
      setDrawnMarkersMap(updatedGeoMap);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.markerData, visibleMarkerSets]);

  const handleLayerToggle = (layer: string) => {
    if(vccMap){
      if( layer === MapLayers.ALL_ANNOTATIONS){
        if(vccMap.getLayer(MapLayers.ANNOTATIONS_PERIMETER)){
          vccMap.removeLayer(MapLayers.ANNOTATIONS_PERIMETER)
        }
        else{
          drawAnnotationLayerUtil(vccMap, MapLayers.ANNOTATIONS_PERIMETER);
        }
        if(vccMap.getLayer(MapLayers.ANNOTATIONS_DETOUR)){
          vccMap.removeLayer(MapLayers.ANNOTATIONS_DETOUR)
        }
        else{
          drawAnnotationLayerUtil(vccMap, MapLayers.ANNOTATIONS_DETOUR);
        }
        if(vccMap.getLayer(MapLayers.ANNOTATIONS_BLOCKAGE)){
          vccMap.removeLayer(MapLayers.ANNOTATIONS_BLOCKAGE)
        }
        else{
          drawAnnotationLayerUtil(vccMap, MapLayers.ANNOTATIONS_BLOCKAGE);
        }
      }
      else if(vccMap.getLayer(layer)){
        vccMap.removeLayer(layer);
      }
      else{
        switch(layer){
          case MapLayers.TRAFFIC_LAYER:
            drawTrafficLayerUtil(vccMap);
            break;
          case MapLayers.CAMERA_LAYER:
            drawCameraLayerUtil(vccMap);
            break;
          case MapLayers.HIGHWAY_ALERTS_DATA:
              drawTravelerInfoDataUtil(vccMap);
              break;
        }
      }
    }
  }
  
  const handleMarkerToggle = (markerSets: string[]) => {
    setVisibleMarkerSets(markerSets);
  }

  // VCC Map Annotations
  const handleToggleAnnotationsLegend = () => {
    if([...newFeatures, ...updatedFeatures, ...deletedFeatures].length > 0){
      setConfirmDiscardEdit(true);
    }
    else{
      if(!showAnnotationControl){
        dispatch(setMapEditIncLocationRequest(false));
      }
      setShowAnnotationControl((prevOpen) => !prevOpen);

    }
  }
  const handleAddBlockage = () => {
    if(vccDraw){
      setDrawType(DrawTypes.BLOCKAGE);
      vccDraw.changeMode('draw_line_string');
    }
  }
  const handleAddDetour = () => {
    if(vccDraw){
      setDrawType(DrawTypes.DETOUR);
      vccDraw.changeMode('draw_line_string');  
    }
  }
  const handleAddPerimeter = () => {
    setIsPerimeterModalOpen(true);
  }
  const handleSetPerimeterDetails = (radius: number, note: string) => {
    setIsPerimeterModalOpen(false);
    if(vccDraw){
      setDrawType(DrawTypes.PERIMETER);
      setPerimeterRadius(radius);
      setPerimeterNote(note);
      vccDraw.changeMode('draw_point');
    }
  }
  
  const handleSaveAnnotations = () => {
    if(vccDraw){
      if(props.incId){
        newFeatures.forEach(f => dispatch(createMapAnnotationRequest(props.incId!, f.id as string, JSON.stringify(f), f.properties!.note as string, JSON.stringify(f.properties!.primaryLocation), f.properties!.perimeterRadius as string)));
        updatedFeatures.forEach( f => dispatch(updateMapAnnotationRequest(props.incId!, f.id as string, JSON.stringify(f), f.properties!.note as string, JSON.stringify(f.properties!.primaryLocation), f.properties!.perimeterRadius as string)));
        deletedFeatures.forEach(d => dispatch(deleteMapAnnotationRequest(props.incId!, d.id as string)));
      }      
    }
  }
  const handleEditAnnotations = () => {
    if(vccDraw){
      mapStore.mapAnnotations.forEach( geo => {
        vccDraw.add(JSON.parse(geo.geoJsonString));
      })
    }
  }
  const handleCancelAnnotations = () => {
    if(vccDraw){
      newFeatures.forEach( f => vccDraw.delete(f.id as string))
      setNewFeatures([]);
      setUpdatedFeatures([]);
      setDeletedFeatures([]);
    }

    if (props.incId){
      dispatch(getMapAnnotationsRequest(props.incId))
    }    
    setConfirmDiscardEdit(false);
    setShowAnnotationControl(false);
  }

  const handleCancelMapLocationEdit = (latitude: number, longitude: number, address: string) => {
    // Close the edit control and reset the incident location
    dispatch(setMapEditIncLocationRequest(false));
    dispatch(setIncLatLongFromMapRequest(latitude, longitude));
    dispatch(setIncAddressFromMapRequest(address));
  }

  const handleConfirmMapLocationEdit = () => {
    // Close the edit control - incident location updated via map clicks already
    dispatch(setMapEditIncLocationRequest(false));
  }

  return (
    <>
      <Grid container justifyContent="center" style={{ height: "85vh" }}>
        <Grid item xs={12} style={{ height: "100%" }}>
          <Box style={{ height: "100%", position: "relative" }}>
            {/* Position legends and controls in same div as the map */}
            <Box m={1} mr={6} className={classes.layerPanel}>
              <VccControlPanel props={{
                  allAvailLayers: props.allAvailableLayers,
                  allAvailMarkerSets: props.allAvailableMarkerSets,
                  selectedMarkerSets: visibleMarkerSets,
                  initLayers: props.initLayers,
                  initMarkerSets: props.initMarkerSets,
                  handleMarkerToggle: handleMarkerToggle,
                  handleLayerToggle:  handleLayerToggle,
                }} 
              />
            </Box>
            {(props.showAnnotateTools && !annotationsHidden)  && (
              <Box mt={1} className={classes.annotateButton}>
                <Button
                  variant="contained"
                  color={'inherit'}
                  style={{zIndex: 1, float: 'right', marginRight: '50px'}}
                  onClick={handleToggleAnnotationsLegend}
                  data-rum-id={`annotate-map-toggle__${props.incId}`}
                  endIcon={<ModeIcon />}>
                    Annotate Map
                </Button>
              </Box>
            )}
            {showAnnotationControl && <AnnotationControlPanel props={{
                incId: props.incId,
                drawnFeatures: [...newFeatures, ...updatedFeatures, ...deletedFeatures],
                isDoneSaving: isDoneSaving,
                handleAddPerimeter: handleAddPerimeter,
                handleAddBlockage: handleAddBlockage,
                handleAddDetour: handleAddDetour,
                handleSave: handleSaveAnnotations,
                handleEdit: handleEditAnnotations,
                handleCancel: () => setConfirmDiscardEdit(true),
              }}
              />
            }
            {mapStore.isEditIncLocation && <IncLocationControlPanel props={{
                handleCancel: handleCancelMapLocationEdit,
                handleConfirm: handleConfirmMapLocationEdit,
              }}
              />
            }
            <TrafficPanel />

            {/* Map requires its own empty div to render in */}
            <div style={{ height: "100%" }} ref={mapRef} id="map" className="fullheight-map" />
          </Box>
        </Grid>
      </Grid>
      <PerimeterDetailsModal props={{
          isOpen: isPerimeterModalOpen,
          handleSetPerimeterDetails: handleSetPerimeterDetails,
          handleClose: () => setIsPerimeterModalOpen(false)
        }}
      />
      <CameraModal props={{
        isOpen: isCameraModalOpen,
        cameraData: cameraData,
        handleClose: () => setIsCameraModalOpen(false)
      }} />
      <ConfirmationModal props={{
          isOpen: confirmDiscardEdit,
          title: "Discard Map Annotations?",
          message: "You have unsaved map annotations, do you want to discard them?",
          cancelText: "Continue Editing",
          confirmText: "Discard",
          handleCancel: () => setConfirmDiscardEdit(false),
          handleConfirm: handleCancelAnnotations,
        }} 
      />
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default VccMap;
