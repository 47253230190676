import React, { FC, useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Tooltip,
  Snackbar,
  Box,
  Typography,
  LinearProgress,
  Button,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { IRootReducer, UserModel } from "../../../reducers";
import InviteUser from "../user-operation/invite-user";
import ChangeUserStatus from "../user-operation/change-user-status";
import ReInviteUser from "../user-operation/re-invite-user";
import { AdminStoreSuccess } from "../../../enums/store-messages/admin";
import { getUsersRequest } from "../../../actions";
import {
  DataGrid,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { ParsePhoneNumberUtil } from "../../../utils/string-utils";
import EditUser from "../user-operation/edit-user";

export interface UserGridRow {
  id: string;
  name: string;
  agency: string;
  enabled: string;
  status: string;
  email?: string;
  phoneNumber: string;
  cognitoUsername: string;
}

const AdminGrid: FC = () => {
  const [rowSelection, setRowSelection] = useState({} as UserGridRow);
  const [userSelection, setUserSelection] = useState({} as UserModel);
  const [selectedRows, setSelectedRows] = useState({} as GridRowSelectionModel);
  const [invitedEmail, setInvitedEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [gridData, setGridData] = useState([] as UserGridRow[]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0
  })
  const dispatch = useDispatch();

  const store = useSelector((state: IRootReducer) => state.adminReducer);

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    dispatch(getUsersRequest());
    setIsLoading(true);
    showLoadingAnimation();
  }, [dispatch, showLoadingAnimation]);

  useEffect(() => {
    let apiRows: UserGridRow[] = [];
    store.users?.forEach((u) => {
      apiRows.push({
        id: u.email,
        name: u.firstName + u.lastName ? u.firstName + " " + u.lastName : "N/A",
        agency: u.agency ?? "N/A",
        enabled: u.enabled === "Y" ? "Active" : "Disabled",
        // If a user has a congitoUsername, then they have completed signup
        status: u.cognitoUsername !== "" ? "Confirmed" : "Unconfirmed", 
        email: u.email,
        phoneNumber: u.phoneNumber
          ? ParsePhoneNumberUtil(u.phoneNumber)
          : "N/A",
        // If a user is an original Cognito User preSAW, use their email as their cognitoUsername
        cognitoUsername: u.cognitoUsername !== "" ? u.cognitoUsername : u.email,
      });
    });

    setGridData(apiRows);
  }, [store.users]);

  useEffect(() => {
    if (store.successMessage) {
      switch (store.successMessage) {
        case AdminStoreSuccess.CREATE_AND_INVITE_USER_SUCCESS:
          dispatch(getUsersRequest());
          break;
        case AdminStoreSuccess.REINVITE_USER_SUCCESS:
        case AdminStoreSuccess.DISABLE_USER_SUCCESS:
        case AdminStoreSuccess.ENABLE_USER_SUCCESS:
        case AdminStoreSuccess.UPDATE_USER_SUCCESS:
          dispatch(getUsersRequest());
          setRowSelection({} as UserGridRow);
          setSelectedRows({} as GridRowSelectionModel);
          break;
      }

      setSuccessMessage(store.successMessage);
    }
    if (store.failureMessage) {
      setErrorMessage(store.failureMessage);
    }
  }, [
    store.successMessage,
    store.failureMessage,
    dispatch,
    invitedEmail,
  ]);

  function showToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const handleRowSelection = (newSelectedRows: GridRowSelectionModel) => {
    setSelectedRows(newSelectedRows);
    if (newSelectedRows.length === 1) {
      setRowSelection(gridData.find((d) => d.id === newSelectedRows[0])!);
      setUserSelection(
        store.users.find((d) => d.email === newSelectedRows[0])!
      );
    }
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "agency", headerName: "Agency", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "enabled", headerName: "VCC Status", flex: 1 },
    { field: "status", headerName: "Account Status", flex: 1 },
  ];

  return (
    <Paper>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item>
              <Typography color="inherit" variant="h5" component="h1">
                Users
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs
              id="admin-grid"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Box m={1}>
                  <EditUser
                    props={{
                      rowCountSelected: selectedRows?.length,
                      userSelection: userSelection,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <ChangeUserStatus
                    props={{
                      rowCountSelected: selectedRows?.length,
                      userSelection: userSelection,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <ReInviteUser
                    props={{
                      rowCountSelected: selectedRows?.length,
                      rowSelection: rowSelection,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <InviteUser
                    props={{
                      setInvitedEmail: (invitedEmail: string) =>
                        setInvitedEmail(invitedEmail),
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box m={1}>
                  <Tooltip title="Reload">
                    <Button
                      color="inherit"
                      variant="contained"
                      style={{ minWidth: "150px", color: "black" }}
                      endIcon={<RefreshIcon />}
                      onClick={() => dispatch(getUsersRequest())}
                    >
                      Refresh
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box>
        <Box m={1}>{isLoading && <LinearProgress />}</Box>
        <Box style={{ display: "flex", height: "75vh", width: "100%" }}>
          <DataGrid
            rows={gridData}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            checkboxSelection
            disableColumnSelector={true}
            disableRowSelectionOnClick
            components={{
              Toolbar: showToolbar,
            }}
            onRowSelectionModelChange={(s) => handleRowSelection(s)}
          />
        </Box>
      </Box>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setErrorMessage("")}
      >
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setSuccessMessage("")}
      >
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default AdminGrid;
