import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { reinviteUserRequest } from "../../../../actions";
import { UserGridRow } from "../../admin-grid";

interface ReInviteUserProps {
  props: {
    rowCountSelected: number;
    rowSelection?: UserGridRow;
  };
}

const ReInviteUser: FC<ReInviteUserProps> = ({ props }) => {
  const [open, setOpen] = useState(false);
  const isDisabled = isBtnDisabled();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  function isBtnDisabled() {
    if (
      props.rowCountSelected === 1 &&
      props.rowSelection?.status === "Unconfirmed"
    )
      return false;
    else return true;
  }

  const handleDisableAndClose = () => {
    dispatch(reinviteUserRequest(props.rowSelection?.email!));
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Resend an invite for a single user in the VCC">
        <Box>
          <Button
            style={{ minWidth: "150px", color: "black" }}
            variant="contained"
            color="inherit"
            onClick={handleOpen}
            disabled={isDisabled}
          >
            Re-Invite User
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Re-Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to re-invite user:
          </DialogContentText>
          <DialogContentText>
            Name: {props.rowSelection?.name} <br />
            Email: {props.rowSelection?.email} <br />
            Agency: {props.rowSelection?.agency} <br />
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDisableAndClose} color="primary">
              Re-Invite
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReInviteUser;
