import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGroupsForUserRequest, updateUserRequest } from "../../../../actions";
import { IRootReducer, UserModel } from "../../../../reducers";
import { CleanPhoneNumberUtil } from "../../../../utils/string-utils";

interface ChangeUserStatusProps {
  props: {
    rowCountSelected: number;
    userSelection: UserModel;
  };
}

const ChangeUserStatus: FC<ChangeUserStatusProps> = ({ props }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({} as UserModel);

  const userGroupsStore = useSelector(
    (state: IRootReducer) => state.adminReducer.groupsForUser
  );

  const handleOpen = () => {
    setOpen(true);
    dispatch(getGroupsForUserRequest(props.userSelection?.cognitoUsername));
    setUserDetails(props.userSelection);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleStatusChangeAndClose = () => {
    let cleanNumber = "";
    if (userDetails.phoneNumber) {
      cleanNumber = CleanPhoneNumberUtil(userDetails.phoneNumber);
    }
    
    dispatch(
      updateUserRequest(
        userDetails.cognitoUsername,
        userDetails.email,
        userDetails.firstName,
        userDetails.lastName,
        cleanNumber,
        userDetails.agency,
        userDetails.enabled === "Y" ? "N" : "Y", // If enabled, disabled. If disabled, enable
        userGroupsStore,
        [],
        []
      )
    );
    
    setOpen(false);
  };

  function isDisableAction() {
    return (
      props.rowCountSelected === 1 && props.userSelection?.enabled === "Y"
    );
  }

  return (
    <>
      <Tooltip
        title={
          (isDisableAction() ? "Disable" : "Re-enable") +
          " access for a single user from the VCC"
        }
      >
        <Box>
          <Button
            style={{ minWidth: "150px", color: "black" }}
            variant="contained"
            color="inherit"
            onClick={handleOpen}
            disabled={props.rowCountSelected !== 1}
          >
            {isDisableAction() ? "Disable User" : "Re-Enable User"}
          </Button>
        </Box>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {isDisableAction() ? "Disable User" : "Re-Enable User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to{" "}
            {isDisableAction() ? "disable" : "re-enable"} VCC access to:
          </DialogContentText>
          <DialogContentText>
            Name: {props.userSelection?.firstName + " " + props.userSelection?.lastName} <br />
            Email: {props.userSelection?.email} <br />
            Agency: {props.userSelection?.agency} <br />
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleStatusChangeAndClose} color="primary">
              {isDisableAction() ? "Disable" : "Re-enable"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeUserStatus;
