import { UserModel } from "../../reducers/states/admin";
import { types } from "../types";

// Get All Users
export const getUsersRequest = () => ({
  type: types.GET_USERS,
});
export const getUsersSuccess = (users: UserModel[]) => ({
  type: types.GET_USERS_SUCCESS,
  payload: { users },
});
export const getUsersFailure = (getUsersFailureMessage: string) => ({
  type: types.GET_USERS_FAILURE,
  payload: { getUsersFailureMessage },
});

// Create and Invite User
export interface createAndInviteUserPayload {
  email: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  agency: string;
  roles: string[];
}
export const createAndInviteUserRequest = (
  email: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  agency: string,
  roles: string[],
) => ({
  type: types.CREATE_AND_INVITE_USER,
  payload: {
    email,
    givenName,
    familyName,
    phoneNumber,
    agency,
    roles,
  } as createAndInviteUserPayload,
});
export const createAndInviteUserSuccess = (successMessage: string) => ({
  type: types.CREATE_AND_INVITE_USER_SUCCESS,
  payload: { successMessage },
});
export const createAndInviteUserFailure = (failureMessage: string) => ({
  type: types.CREATE_AND_INVITE_USER_FAILURE,
  payload: { failureMessage },
});

// Update User Attributes
export interface updateUserPayload {
  cognitoUsername: string;
  email: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
  agency: string;
  enabled: string;
  roles: string[];
  rolesToAdd: string[];
  rolesToRemove: string[];
}
export const updateUserRequest = (
  cognitoUsername: string,
  email: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  agency: string,
  enabled: string,
  roles: string[],
  rolesToAdd: string[],
  rolesToRemove: string[],
) => ({
  type: types.UPDATE_USER,
  payload: {
    cognitoUsername,
    email,
    givenName,
    familyName,
    phoneNumber,
    agency,
    enabled,
    roles,
    rolesToAdd,
    rolesToRemove,
  } as updateUserPayload,
});
export const updateUserSuccess = (updateUserSuccessMessage: string) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: { updateUserSuccessMessage },
});
export const updateUserFailure = (updateUserFailureMessage: string) => ({
  type: types.UPDATE_USER_FAILURE,
  payload: { updateUserFailureMessage },
});

// Update User Profile Attributes
export interface updateUserProfilePayload {
  cognitoUsername: string;
  givenName: string;
  familyName: string;
  phoneNumber: string;
}
export const updateUserProfileRequest = (
  cognitoUsername: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
) => ({
  type: types.UPDATE_USER_PROFILE,
  payload: {
    cognitoUsername,
    givenName,
    familyName,
    phoneNumber,
  } as updateUserProfilePayload,
});
export const updateUserProfileSuccess = (updateUserProfileSuccessMessage: string) => ({
  type: types.UPDATE_USER_PROFILE_SUCCESS,
  payload: { updateUserProfileSuccessMessage },
});
export const updateUserProfileFailure = (updateUserProfileFailureMessage: string) => ({
  type: types.UPDATE_USER_PROFILE_FAILURE,
  payload: { updateUserProfileFailureMessage },
});

// List Groups by User
export interface listGroupsByUserPayload {
  cognitoUsername: string;
}
export const getGroupsForUserRequest = (cognitoUsername: string) => ({
  type: types.GET_GROUPS_BY_USER,
  payload: { cognitoUsername } as listGroupsByUserPayload,
});
export const getGroupsForUserSuccess = (groupsForUser: string[]) => ({
  type: types.GET_GROUPS_BY_USER_SUCCESS,
  payload: { groupsForUser },
});
export const getGroupsForUserFailure = (
  listGroupsByUserFailureMessage: string
) => ({
  type: types.GET_GROUPS_BY_USER_FAILURE,
  payload: { listGroupsByUserFailureMessage },
});

// Add User to Group
export interface addUserToGroupPayload {
  cognitoUsername: string;
  group: string;
}
export const addUserToGroupRequest = (cognitoUsername: string, group: string) => ({
  type: types.ADD_USER_TO_GROUP,
  payload: {
    cognitoUsername,
    group,
  } as addUserToGroupPayload,
});
export const addUserToGroupSuccess = (addUserToGroupSuccessEmail: string) => ({
  type: types.ADD_USER_TO_GROUP_SUCCESS,
  payload: { addUserToGroupSuccessEmail },
});
export const addUserToGroupFailure = (addUserToGroupFailureEmail: string) => ({
  type: types.ADD_USER_TO_GROUP_FAILURE,
  payload: { addUserToGroupFailureEmail },
});

// Remove User From Group
export interface removeUserFromGroupPayload {
  cognitoUsername: string;
  group: string;
}
export const removeUserFromGroupRequest = (cognitoUsername: string, group: string) => ({
  type: types.REMOVE_USER_FROM_GROUP,
  payload: {
    cognitoUsername,
    group,
  } as removeUserFromGroupPayload,
});
export const removeUserFromGroupSuccess = (
  removeUserFromGroupSuccessMessage: string
) => ({
  type: types.REMOVE_USER_FROM_GROUP_SUCCESS,
  payload: { removeUserFromGroupSuccessMessage },
});
export const removeUserFromGroupFailure = (
  removeUserFromGroupFailureMessage: string
) => ({
  type: types.REMOVE_USER_FROM_GROUP_FAILURE,
  payload: { removeUserFromGroupFailureMessage },
});

// Reinvite User
export interface reinviteUserPayload {
  email: string;
}
export const reinviteUserRequest = (email: string) => ({
  type: types.REINVITE_USER,
  payload: { email } as reinviteUserPayload,
});
export const reinviteUserSuccess = (reinviteSuccessEmail: string) => ({
  type: types.REINVITE_USER_SUCCESS,
  payload: { reinviteSuccessEmail },
});
export const reinviteUserFailure = (reinviteFailureEmail: string) => ({
  type: types.REINVITE_USER_FAILURE,
  payload: { reinviteFailureEmail },
});
